.mywork {
  color: var(--color-info-light);
  width: 100%;
  padding: 0 60px 0 60px;
  margin-top: 30px !important;
  overflow: hidden;
}
.mywork-title {
  margin-bottom: 30px;
  text-align: center;
}
.mywork-title h2 {
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 400;
  margin-bottom: 20px;
  color: var(--color-title);
  font-weight: 600;
}
.project-links {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.mywork-title h3 {
  font-size: 2.2rem;
  text-transform: uppercase;
  margin-bottom: 40px;
}
.project {
  overflow: hidden !important;
  min-width: 300px;
  background: var(--color-card2);
  border: 1px solid rgb(255 255 255 / 0.1);
  border-radius: 17px;
  padding: 20px;
}
.project-img {
  overflow: hidden;
  display: block;
  position: relative;
}
.project-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.work-img {
  width: 100%;
  display: inline-block !important;
  border-radius: 25px !important;
  padding: 15px;
  transition: 0.5s;
  height: 220px;
  cursor: pointer;
  transform: scale(1) !important;
}
.project:hover .work-img {
  transform: scale(1.06) !important;
}
.project {
}
.date-posted {
  display: flex !important;
  align-items: center !important;
  padding: 10px 0;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}

.work-details {
  padding: 0px 20px 20px 20px;
  position: relative;
}
.work-details h2 {
  font-size: 1.3rem;
  margin-bottom: 20px;
  max-height: 100px;
  font-weight: 700;
}
.work-details p {
  font-size: 13px;
  word-break: break-all;
  font-weight: 300 !important;
  line-height: 1.7rem;
  white-space: normal;
}
.admin {
  font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", monospace;
}
.who-post {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-title) !important;
}
.who-post p {
  margin: 0 !important;
  margin-left: 5px !important;
  text-transform: uppercase;
  color: var(--color-button-hover) !important;
  font-size: 12px !important;
  background: var(--color-button);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600 !important;
}
.work-info {
  font-size: 12px;
}

.project {
  /* border-bottom: 1px solid var(--color-title); */
}
.project .learnmore {
  background: none;
  padding-right: 10px;
  display: flex;
  font-weight: 100;
  letter-spacing: 1px;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  padding-top: 10px;
  text-transform: uppercase;
  font-weight: 300;
  color: #909faf !important;
  cursor: pointer;
  /* border: 1px solid var(--color-button-hover) !important; */
  color: var(--color-button);
  border-radius: 4px;
}
.learnmore i {
  margin-bottom: 2.1px;
}
.learnmore:hover {
  color: var(--color-button-hover);
}
.project:hover {
  border-bottom: 4px solid var(--color-button);
}

/*  */

.light .project {
  background: var(--color-card-background);
  box-shadow: var(--card-box-shadow);
  margin-top: 20px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 768px) {
  .project {
    width: 200px;
  }
  .mywork .mywork-title {
    text-align: center;
  }
  .mywork .mywork-title h3 {
    font-size: 1.5rem;
  }
  .work-togather-text {
    padding-bottom: 20px;
  }
  .sec-btn {
    font-size: 13px;
  }
  .work-togather-form {
    padding-left: 0px;
  }
  .project-row {
  }
  .owl-item {
    display: flex !important;
    justify-content: center;
  }
  .owl-dots {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 425px) {
  .mywork-title {
    text-align: center;
  }
  .mywork-title h3 {
    font-size: 1.3rem !important;
    word-wrap: wrap;
  }
  .mywork {
    padding: 0 0 0 0px;
  }
  .project {
  }
  .work-img {
    padding: 10px;
  }
  .work-details {
    padding: 0 10px 10px 10px;
  }
}
@media only screen and (max-width: 360px) {
  .project {
    padding: 10px;
    min-width: 280px !important;
  }
  .mywork {
    padding-left: 20px !important;
  }
}

.light .work-details h2 {
  color: black !important;
  font-family: "poppins", sans-serif !important;
}
.light .who-post p {
  color: #ff5652 !important;
}
.light .project .learnmore {
  font-size: 11px;
  color: rgb(5, 5, 5) !important;
}
