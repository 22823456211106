@import url("https://fonts.googleapis.com/css2?family=Meddon&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inconsolata&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap");
#header {
  transition: all 0.5s;

  z-index: 997;
}
#header.header-inner-pages,
#header.header-scrolled {
  background-color: rgb(15 23 42/ 0.8) !important;
}
#header .logo {
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 2px;
  font-family: "Mr Dafoe", cursive;
  font-size: 20px !important;
}
#header .logo:hover {
  text-decoration: underline !important;
}
#header .logo a {
  color: var(--color-white) !important;
}
#header .logo img {
  max-height: 40px;
}
.navi {
  max-width: 2700px !important;
  margin: auto;
  justify-content: space-between !important;
  padding-left: 60px;
  padding-right: 60px;
}
.lefty {
  width: 100% !important;
  margin: auto !important;
  display: flex !important;
}
.navbur .myname {
  font-family: "Playfair Display", serif !important;

  font-weight: 800;
  line-height: 1;
  color: var(--white);
  background: var(--color-white);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.navbur {
  background: var(--color-background) !important;
  box-shadow: rgb(0, 10, 24) 0px 0px 10px -10px;
  padding: 20px 0;
  position: relative;
  border-bottom: 0.1px solid var(--color-background);

  transition: all 0.5s ease !important;
}
nav {
  display: flex !important;
  float: right;
  padding-right: 40px;
}
.nav-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.nav-menu > ul {
  display: flex;
}
.nav-menu > ul > li {
  position: relative;
  white-space: nowrap;
  padding: 10px 0 10px 24px;
}

.nav-menu > ul > li a {
  font-size: 14px !important;
  letter-spacing: 2px;
  font-weight: 100;

  position: relative;
  font-style: normal;
  font-family: "Share Tech Mono", monospace;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}
.nav-menu > ul > li span {
  color: #7eeb7e !important;
}

.descript {
  margin-top: 160px !important;
  /* padding-top: 4rem !important; */
}
.left-btns {
  display: flex;
  align-items: center;
}
.left-btns .CvMe {
  margin-left: 20px;
}

.CvMe button {
  background: var(--color-button-hover) !important;
  height: 50px;
  border-radius: 5px;
  width: 130px;
  color: #fff;
  font-size: 13px;
}
.active a {
  color: var(--color-button-hover) !important;
  font-weight: 700 !important;
}
.light .active a {
  color: #ff5652 !important;
}
.scrolled {
  background: #04133a !important;
}
.myname {
  font-size: 2vw;
  font-family: "Playfair Display", Sans-serif;
  text-transform: uppercase;
  font-weight: 800;
}
#header.header-inner-pages,
#header.header-scrolled {
  background: rgba(24, 6, 185, 0.8) !important;
}
#theme-button {
  margin-bottom: -5px !important;
}
#theme-button i {
  cursor: pointer;
  font-size: 30px !important;

  color: #cae1e4 !important;
}
#theme-button2 {
  position: fixed;
  background: transparent !important;
  width: 46px;
  border: 1px solid #adadad;
  height: 46px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 50px;
  justify-content: center;
  right: 20px;
  bottom: 80px !important;
  margin-top: 20px;
}

.toggle {
  transition: all 500ms;
  position: relative;
  --size: 1.4rem;
  appearance: none;
  outline: none;
  cursor: pointer;
  width: var(--size);
  height: var(--size);
  border-radius: 999px;

  --ray-size: calc(var(--size) * -0.4);
  --offset-orthogonal: calc(var(--size) * 0.65);
  --offset-diagonal: calc(var(--size) * 0.45);
  transform: scale(0.75);
  color: rgb(255, 230, 0);
  box-shadow: inset 0 0 0 var(--size),
    calc(var(--offset-orthogonal) * -1) 0 0 var(--ray-size),
    var(--offset-orthogonal) 0 0 var(--ray-size),
    0 calc(var(--offset-orthogonal) * -1) 0 var(--ray-size),
    0 var(--offset-orthogonal) 0 var(--ray-size),
    calc(var(--offset-diagonal) * -1) calc(var(--offset-diagonal) * -1) 0
      var(--ray-size),
    var(--offset-diagonal) var(--offset-diagonal) 0 var(--ray-size),
    calc(var(--offset-diagonal) * -1) var(--offset-diagonal) 0 var(--ray-size),
    var(--offset-diagonal) calc(var(--offset-diagonal) * -1) 0 var(--ray-size);
}
.toggle:checked {
  --size: 1.7rem;
  appearance: none;
  outline: none;
  cursor: pointer;
  width: var(--size);
  height: var(--size);
  box-shadow: inset calc(var(--size) * 0.33) calc(var(--size) * -0.25) 0;
  border-radius: 999px;
  color: #0a0a0a;
  position: relative;
}

.toggle {
  z-index: 1;
}

/* mobile responsive */
@media only screen and (max-width: 1024px) {
  .navi {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media only screen and (max-width: 768px) {
  .menuBtn {
    height: 30px;
    width: 30px;
    position: absolute;
    right: 20px;
    top: 0px;
    z-index: 101;
  }
  .menuBtn > span {
    background-color: var(--color-white);
    border-radius: 1px;
    height: 3px;
    width: 90%;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -1px 0 0 -15px;
    transition: height 100ms;
  }
  .menuBtn > span:after,
  .menuBtn > span:before {
    content: "";
    background-color: var(--color-white);
    border-radius: 1px;
    height: 3px;
    width: 70%;
    position: absolute;
    left: 50%;
    margin-left: -15px;
    transition: all 200ms;
  }
  .menuBtn > span:after {
    top: -7px;
  }
  .menuBtn > span:before {
    bottom: -7px;
  }
  .menuBtn.act > span {
    height: 0;
  }
  .menuBtn.act > span:after,
  .menuBtn.act > span:before {
    background-color: #008877;
    top: 1px;
  }
  .menuBtn.act > span:after {
    transform: rotate(45deg);
  }
  .menuBtn.act > span:before {
    transform: rotate(-45deg);
  }
  nav {
    padding: 10px !important;
  }

  .mainMenu {
    background-color: var(--color-background);
    position: absolute;
    right: -20px;
    top: -20px;
    z-index: 100;
    height: 100vh;
    width: 80%;
    z-index: 100;
    display: flex;
    flex-direction: column;
    display: table;
    text-align: left;
    opacity: 0;
    transition: all 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transform: scale(0);
  }
  .mainMenu ul {
    padding-top: 100px;
    border-top: 2px solid #e5ebea;
  }
  .mainMenu.act {
    opacity: 1;
    transform: scale(1);
  }
  .mainMenu.act ul li {
    opacity: 1;
    transform: translateX(0);
  }
  .mainMenu ul {
    display: table-cell;
    vertical-align: middle;
  }
  .mainMenu li {
    padding: 12px 0;
    transition: all 400ms 510ms;
    opacity: 0;
  }
  .mainMenu li:nth-child(odd) {
    transform: translateX(30%);
  }
  .mainMenu li a:nth-child(even) {
    transform: rotate(30deg) !important;
  }
  .mainMenu li:last-child {
    transform: none;
  }
  .mainMenu a {
    color: var(--color-white);
    display: inline-block;
    text-decoration: none !important;
    font-size: 1.2rem !important;
    transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
    -webkit-text-stroke: 1px;
    opacity: 0.8;

    font-weight: 300 !important;
  }

  .mainMenu li:hover a {
    font-weight: 800;
    will-change: transform;
    opacity: 0.2;
    -webkit-text-fill-color: transparent;
    transform: translateX(20px);
  }
  .mainMenu a.suBtn {
    color: #fff;
  }
  .suBtn {
    background-color: blue;
    border-radius: 5px;
    padding: 10px 20px;
  }
  .nav-social i {
    display: none;
  }
  .lefty {
    padding-left: 0 !important;
  }
  .navi {
    padding-left: 10px !important;
  }
  .CvMe {
    display: none;
  }
}
.toggle:checked {
  background: #ffffff !important;
}

@media only screen and (max-width: 600px) {
  .left-btns {
    position: absolute;
    right: -20px;
    bottom: 30px;
  }
  .myname {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 425px) {
  .left-btns {
    position: absolute;
    right: -20px;
    bottom: 30px;
  }
  .mainMenu {
    width: 100%;
    right: 0px !important;
  }
  #theme-button2 {
    right: 15px;
  }
  .devman {
    font-size: 1.4rem;
  }
}
@media only screen and (max-width: 320px) {
  .left-btns {
    position: absolute;
    right: -30px;
    bottom: 30px;
  }
}
.devman {
  color: var(--color-button-hover) !important;
  font-size: 1.6rem !important;
  display: flex;
  justify-content: center;
  margin-bottom: 0 !important;

  font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", monospace;
  align-items: center;
  font-weight: 800 !important;
}
.devman span {
  color: rgb(39, 47, 56);
}
.devman img {
  width: 30px;
  margin-right: 5px;
}
.nav-social i {
  font-size: 18px;
  margin-top: 2px;
  margin-right: 15px;
  color: #adadad;
}
.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

/* light theme settings */

.light .scrolled {
  background: lightblue !important;
}
.light .navbur {
  background: #ffffff !important;
}
.light .navbur nav ul li a {
  font-weight: 500;
}
.light .myname {
  color: #000000 !important;

  font-family: "Playfair Display", serif !important;
  font-weight: 800;
  line-height: 1;
  color: var(--white);
  background: rgb(26, 25, 25);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.light .navbur {
  border-bottom: none !important;
  background: none;
}
.light .CvMe button {
  background: var(--btn-background) !important;
}

.light #theme-button2 {
  background-color: #fff !important;
}
.light .devman {
  color: #ff5652 !important;
}
